<template>
  <div class="page">
    <div class="creation-box">
      <!-- 有内容时显示 -->
      <div class="left" v-if="detail.length">
        <list
          isCreation
          :creation="false"
          :discoveryList="detail"
          @deleteInd="(e) => detail.splice(e, 1)"
        >
        </list>
        <!-- 分页 -->
        <div class="foot-page" v-if="total > 10">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            current-page.sync="5"
            :page-size="limit"
            layout="prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>

      <!-- 暂无数据时显示 -->
      <div class="left" v-else>
        <div class="null-box">
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </div>

      <!-- 右侧个人信息区 -->
      <div class="right">
        <div class="box">
          <div class="bottom">
            <img class="headPortrait" :src="userPhoto" />
            <span>{{ user.name ? user.name : "请登录" }}</span>
            <div class="group">
              <div class="left" @click="xin.goUrl('/userCenter?index=4')">
                <span>{{ user.fans }}</span>
                <span class="subTitle">粉丝</span>
              </div>
              <div class="line"></div>
              <div class="left" @click="xin.goUrl('/userCenter?index=5')">
                <span>{{ user.attention }}</span>
                <span class="subTitle">关注</span>
              </div>
            </div>
            <img @click="gofabu" class="button" src="@image/fabu.png" />
          </div>
        </div>
        <!-- 右侧二屏 -->
        <div class="box3">
          <div
            class="box3-group"
            :class="{ active: activeIndex == index }"
            v-for="(item, index) in user.profit"
            :key="index"
            @click="
              page = 0;
              creationList(list[index].type, index);
            "
          >
            <img :src="list[index].img" />
            <span class="money">￥{{ item[0] }}</span>
            <span class="name">{{ list[index].name }}</span>
            <span class="name">{{ item[1] }}</span>
          </div>
        </div>
        <!-- 信息区 -->
        <messageTwo></messageTwo>
      </div>
    </div>

    <!-- 对话框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="560px"
      :before-close="handleClose"
    >
      <span>学习请先进行身份认证</span>
      <div class="popupsButton">
        <span @click="dialogVisible = !dialogVisible" class="popupsJudgment"
          >取消</span
        >
        <span @click="go" class="popupsJudgment">认证</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import messageTwo from "@components/message/message";
import list from "@components/common/list";
import { mapState } from "vuex";
// import { NoticeBar } from "vue-easy-notice-bar";
export default {
  components: {
    messageTwo,
    list,
    // NoticeBar,
  },

  data() {
    return {
      dialogVisible: false, // 控制对话框的显示与隐藏
      detail: "", // 创作内容详情
      user: {
        name: "",
        fans: 0,
        attention: 0,
        photo: "",
        profit: [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
      },
      // 右侧二屏
      list: [
        {
          img: require("@image/shuoshuo.png"),
          name: "发布说说",
          type: 1,
        },
        {
          img: require("@image/bingli.png"),
          name: "病例分享",
          type: 2,
        },
        {
          img: require("@image/shiping.png"),
          name: "学术视频",
          type: 3,
        },
        {
          img: require("@image/citiao.png"),
          name: "指南解析",
          type: 4,
        },
        {
          img: require("@image/lunwen.png"),
          name: "发布论文",
          type: 11,
        },
        {
          img: require("@image/wenda.png"),
          name: "发布问答",
          type: 5,
        },
      ],
      page: 0,
      limit: 10,
      total: 0,
      title: "", // 搜索关键字
      type: "",
      activeIndex: "",
    };
  },

  created() {
    this.creationList();
  },

  computed: {
    ...mapState("userManagement", ["userInfo", "userParam"]),
    userPhoto() {
      return this.user.photo
        ? this.user.photo
        : this.$http.baseURL + "/static/images/web/1.png";
    },
  },

  methods: {
    //认证弹窗隐藏
    handleClose(done) {
      done();
    },

    // 获取创作列表
    async creationList(type, index) {
      this.activeIndex = index;
      let token = localStorage.getItem("token") || "";
      if (token) {
        this.xin.isLoadding(true);
        let res = await this.$creationApi.creationList({
          page: this.page,
          limit: this.limit,
          title: this.title,
          type: type ? type : "",
        });
        if (res.code == 200) {
          let data = res.data.discover.data || [];
          data.forEach((v) => {
            if (v.images) {
              v.images = JSON.parse(v.images);
            } else {
              v.images = [];
            }
          });
          // if (this.page == 1) {
          //   this.detail = data;
          // } else {
          //   this.detail = detail.concat(data);
          // }
          this.detail = data;
          this.user = res.data.user;
          this.total = res.data.discover.total;
          this.type = type ? type : "";
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
        this.xin.isLoadding();
      }
    },

    // 发布创作
    gofabu() {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }
      if (this.userInfo.company && this.userInfo.company.status == 1) {
        this.$router.push({
          path: "/found-release-index",
        });
      } else {
        this.dialogVisible = true;
      }
    },

    // 跳转到认证页面
    go() {
      this.dialogVisible = false;
      this.$router.push({
        path: "/authentication",
      });
    },

    // pageSize改变时会触发
    async handleSizeChange(e) {
      this.page = e;
      this.xin.isLoadding(true);
      await this.creationList();
      this.xin.isLoadding(false);
    },

    // currentPage改变时会触发
    async handleCurrentChange(e) {
      this.page = e;
      this.xin.isLoadding(true);
      await this.creationList(this.type);
      this.xin.isLoadding(false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.foot-page {
  height: 40px;
  text-align: center;
  width: 100%;
}

.active .name {
  color: $--themeColor;
}

.page {
  > .creation-box {
    display: flex;
    flex-direction: row;
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;

    > .left {
      flex: 1;

      > .leftContent {
        display: flex;
        flex-direction: column;

        > .group-one {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          margin-top: 23px;
          padding-bottom: 30px;
          box-sizing: border-box;
          border-bottom: 1px solid #f2f2f2;
          margin-bottom: 30px;

          > .group-one-box {
            position: relative;

            > .rectangular {
              position: absolute;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 79px;
              height: 26px;
              border-radius: 13px;
              opacity: 0.3;
              background: #000000;
              top: 164px;
              left: 181px;
              font-size: 12px;
              color: #ffffff;

              > img {
                width: 16px;
                height: 18px;
                object-fit: cover;
                margin-right: 4px;
              }
            }

            > .banner {
              width: 270px;
              height: 200px;
              object-fit: cover;
              border-radius: 10px;
              margin-right: 20px;
            }
          }

          > .group-one-box2 {
            position: relative;

            > .rectangular2 {
              position: absolute;
              width: 50px;
              height: 26px;
              opacity: 0.3;
              background: #000000;
              border-radius: 13px;
              font-size: 14px;
              color: #ffffff;
              text-align: center;
              line-height: 26px;
              left: 210px;
              top: 164px;
            }

            > .banner2 {
              width: 270px;
              height: 200px;
              object-fit: cover;
              border-radius: 10px;
              margin-right: 20px;
            }

            > .banner5 {
              width: 270px;
              height: 223px;
              object-fit: cover;
              border-radius: 10px;
              margin-right: 20px;
            }
          }

          > .group-one-right {
            display: flex;
            flex-direction: column;
            color: #8f8f8f;
            font-size: 18px;
            line-height: 30px;

            > .subTitle2 {
              font-size: 24px;
              color: #333333;
              line-height: 1.8;
              margin-bottom: 20px;
            }

            > .subTitle {
              font-size: 24px;
              color: #333333;
              overflow: hidden;
              line-height: 1.8;
              /*必须结合的属性,当内容溢出元素框时发生的事情*/
              text-overflow: ellipsis;
              /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
              display: -webkit-box;
              /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
              -webkit-line-clamp: 3;
              /*用来限制在一个块元素显示的文本的行数。*/
              -webkit-box-orient: vertical;
            }

            > .group-one-right-data {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 18px;
              color: #8f8f8f;
              margin-top: auto;

              > .data-button {
                font-size: 13px;
                color: $--themeColor;
                width: 100px;
                height: 25px;
                border: 1px solid $--themeColor;
                border-radius: 6px;
                text-align: center;
                line-height: 26px;
              }

              > .data-audit {
                color: #e60012;
                margin-left: 15px;
                margin-right: 10px;
              }

              > img {
                width: 16px;
                height: 16px;
                object-fit: cover;
              }

              > .data-data {
                font-size: 18px;
                color: #8f8f8f;
                margin-left: 10px;

                > span {
                  margin-right: 16px;
                }
              }
            }
          }
        }

        > .talk {
          > .group-two {
            display: flex;
            flex-direction: column;
            font-size: 18px;
            color: #8f8f8f;
            padding-bottom: 30px;
            box-sizing: border-box;
            border-bottom: 1px solid #f2f2f2;
            margin-bottom: 30px;

            > .bottom {
              display: flex;
              flex-direction: row;
              margin-top: 15px;

              > .banner3 {
                width: 145px;
                height: 100px;
                object-fit: cover;
                border-radius: 10px;
                margin-right: 20px;
              }

              > div {
                display: flex;
                flex-direction: column;

                > .title {
                  font-size: 24px;
                  color: #333333;
                  margin-bottom: 10px;
                }

                > .subTitle {
                  line-height: 1.8;
                }

                > .group-two-bottom {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  font-size: 18px;
                  color: #8f8f8f;
                  margin-top: auto;

                  > .data-button {
                    font-size: 13px;
                    color: $--themeColor;
                    width: 100px;
                    height: 25px;
                    border: 1px solid $--themeColor;
                    border-radius: 6px;
                    text-align: center;
                    line-height: 26px;
                  }

                  > span {
                    margin-right: 16px;
                  }
                }
              }
            }

            > .group-two-bottom {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 18px;
              color: #333333;
              margin-top: 19px;

              > .button {
                font-size: 13px;
                color: $--themeColor;
                width: 75px;
                height: 25px;
                border: 1px solid $--themeColor;
                border-radius: 6px;
                text-align: center;
                line-height: 26px;
                margin-right: 10px;
              }
            }

            > .title {
              font-size: 24px;
              color: #333333;
              margin-bottom: 10px;
            }

            > .subTitle {
              line-height: 1.8;
            }
          }
        }

        > .group-one-right {
          display: flex;
          flex: 1;
          flex-direction: column;
          margin-left: 20px;
          color: #8f8f8f;
          font-size: 18px;
          line-height: 30px;
          padding-bottom: 30px;
          box-sizing: border-box;
          border-bottom: 1px solid #f2f2f2;
          margin-bottom: 30px;

          &:first-child {
            margin-left: 0;
          }

          > .subTitle2 {
            line-height: 1.8;
            margin-bottom: 4px;
          }

          > .subTitle {
            font-size: 24px;
            color: #333333;
            overflow: hidden;
            line-height: 1.8;
            /*必须结合的属性,当内容溢出元素框时发生的事情*/
            text-overflow: ellipsis;
            /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
            display: -webkit-box;
            /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
            -webkit-line-clamp: 3;
            /*用来限制在一个块元素显示的文本的行数。*/
            -webkit-box-orient: vertical;
          }

          > .data {
            display: flex;
            flex-direction: row;
            margin-top: 20px;

            > span {
              margin-left: 10px;

              &:first-child {
                margin-left: 0;
              }
            }

            > .data-button {
              font-size: 13px;
              color: $--themeColor;
              width: 100px;
              height: 25px;
              border: 1px solid $--themeColor;
              border-radius: 6px;
              text-align: center;
              line-height: 26px;
              margin-right: 10px;
            }
          }
        }
      }
    }

    > .right {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      > .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 294px;
        background: #f2f2f2;
        padding: 15px;
        box-sizing: border-box;
        margin-bottom: 20px;

        > .bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 264px;
          background: #ffffff;
          font-size: 14px;
          color: #333333;
          border-radius: 5px;
          padding: 20px 22px;
          box-sizing: border-box;
          font-size: 20px;

          > .headPortrait {
            width: 60px;
            height: 60px;
            object-fit: cover;
            margin-bottom: 10px;
            border-radius: 50%;
          }

          > .group {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;

            > .left {
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;

              > .subTitle {
                color: #8f8f8f;
                font-size: 14px;
                margin-top: 8px;
              }
            }

            > .line {
              width: 2px;
              height: 30px;
              background: #f2f2f2;
              margin: 0 50px;
            }
          }

          > .button {
            cursor: pointer;
            width: 220px;
            height: 44px;
            object-fit: cover;
            margin-top: 10px;
          }
        }
      }

      > .box3 {
        display: flex;
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;
        width: 294px;
        background: #f2f2f2;
        margin-bottom: 20px;

        > .box3-group {
          &:last-child {
            margin-bottom: 0;
          }

          display: flex;
          flex-direction: row;
          align-items: center;
          color: #333333;
          font-size: 16px;
          margin-bottom: 23px;
          cursor: pointer;

          > img {
            width: 20px;
            height: 20px;
            object-fit: cover;
            margin-right: 10px;
          }

          > span {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .money {
            flex: 1.3;
          }
        }
      }

      > .box2 {
        display: flex;
        flex-direction: column;
        width: 294px;
        background: #f2f2f2;
        padding: 0 15px;
        box-sizing: border-box;

        > .group {
          &:first-child {
            margin-top: 15px;
          }

          display: flex;
          flex-direction: row;
          margin-bottom: 15px;

          > .information {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
            margin-right: 10px;
          }

          > .right {
            display: flex;
            flex-direction: column;
            font-size: 14px;

            > .title {
              color: #333333;
              line-height: 20px;
            }

            > .time {
              color: #8f8f8f;
              margin-top: auto;
            }
          }
        }
      }
    }
  }
}

::v-deep {
  .text-container {
    width: 100% !important;
  }

  .el-dialog {
    display: flex;
    flex-direction: column;
    padding: 70px 80px;
    box-sizing: border-box;
    margin-top: 320px;

    > .el-dialog__body {
      font-size: 24px;
      color: #333333;
      text-align: center;
    }

    .popupsButton {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;

      > .popupsJudgment {
        &:last-child {
          background: $--themeColor;
          border: none;
          color: #ffffff;
        }

        width: 170px;
        height: 50px;
        background: #ffffff;
        border: 1px solid $--themeColor;
        border-radius: 26px;
        color: $--themeColor;
        font-size: 18px;
        text-align: center;
        line-height: 50px;
        margin-top: 60px;
      }
    }
  }

  .el-carousel__item .banner {
    width: 890px;
    height: 400px;
    object-fit: cover;
  }

  .el-carousel__arrow {
    display: none;
  }
}
</style>
